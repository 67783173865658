// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blitzen-bits-privacy-policy-js": () => import("../src/pages/blitzen-bits/privacy-policy.js" /* webpackChunkName: "component---src-pages-blitzen-bits-privacy-policy-js" */),
  "component---src-pages-blitzen-bits-terms-and-conditions-js": () => import("../src/pages/blitzen-bits/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-blitzen-bits-terms-and-conditions-js" */),
  "component---src-pages-bowl-out-privacy-policy-js": () => import("../src/pages/bowl-out/privacy-policy.js" /* webpackChunkName: "component---src-pages-bowl-out-privacy-policy-js" */),
  "component---src-pages-bowl-out-terms-and-conditions-js": () => import("../src/pages/bowl-out/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-bowl-out-terms-and-conditions-js" */),
  "component---src-pages-brickfinity-privacy-policy-js": () => import("../src/pages/brickfinity/privacy-policy.js" /* webpackChunkName: "component---src-pages-brickfinity-privacy-policy-js" */),
  "component---src-pages-brickfinity-terms-and-conditions-js": () => import("../src/pages/brickfinity/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-brickfinity-terms-and-conditions-js" */),
  "component---src-pages-chroma-cruncher-privacy-policy-js": () => import("../src/pages/chroma-cruncher/privacy-policy.js" /* webpackChunkName: "component---src-pages-chroma-cruncher-privacy-policy-js" */),
  "component---src-pages-chroma-cruncher-terms-and-conditions-js": () => import("../src/pages/chroma-cruncher/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-chroma-cruncher-terms-and-conditions-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-super-wall-ball-privacy-policy-js": () => import("../src/pages/super-wall-ball/privacy-policy.js" /* webpackChunkName: "component---src-pages-super-wall-ball-privacy-policy-js" */),
  "component---src-pages-super-wall-ball-terms-and-conditions-js": () => import("../src/pages/super-wall-ball/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-super-wall-ball-terms-and-conditions-js" */),
  "component---src-pages-switchy-ball-privacy-policy-js": () => import("../src/pages/switchy-ball/privacy-policy.js" /* webpackChunkName: "component---src-pages-switchy-ball-privacy-policy-js" */),
  "component---src-pages-switchy-ball-terms-and-conditions-js": () => import("../src/pages/switchy-ball/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-switchy-ball-terms-and-conditions-js" */)
}

